import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

const OurTeam = (props:any) => {
  return (
    <>
     <Grid xs={12} sm={12} md={4} lg={4} sx={{marginBottom:"40px"}}>
                  <Box sx={{ display: { xs: "flex", sm: "flex", md: "flex" }, justifyContent: "flex-start", alignItems:"center", flexDirection:"row" }}>
                          <img src={props.item.img} className='widthOurTeam' />
                          <Box sx={{ marginLeft: "5px" }}>
                            <Typography sx={{ fontSize:{xs:"16px", sm:"16px", md:"16px", lg:"20px"}, fontWeight: "600", marginTop: "0px" }} className="colorWhite" variant="h5">{props.item.name}</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: "600", color:"#D05200" }} variant="body1">{props.item.destination}</Typography>
                          </Box>
                        </Box>
                  </Grid>
    </>
  )
}

export default OurTeam
