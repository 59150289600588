import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, TextField, MenuItem, Select, InputLabel } from "@mui/material";
import "./inlineCss.css"
import OurComp from "../../assets/img/ourComp.png"
import lapRed from "../../assets/img/icon/lapRed.png"
import lapBlack from "../../assets/img/icon/lapBlack.png"
import multUserRed from "../../assets/img/icon/multUserRed.png"
import multiUserBlack from "../../assets/img/icon/multiUserBlack.png"
import userRed from "../../assets/img/icon/userRed.png"
import userBlack from "../../assets/img/icon/userBlack.png"
import shape from "../../assets/img/Shape.png"
import flesh from "../../assets/img/flesh.png"
import online from "../../assets/img/onlineimg.png"
import network from "../../assets/img/network.png"
import sliderpic from "../../assets/img/sliderpic.png"
import sliderpic2 from "../../assets/img/sliderpic2.png"
import sliderpic3 from "../../assets/img/sliderpic3.png"
import Carousel from 'react-material-ui-carousel';
import Photo from "../../assets/img/Photo.png"
import userIcon from "../../assets/img/ic_baseline-person-2.png"

import ItemSlider from "./ItemSlider";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import OurTeam from "./OurTeam";
let OurTeams: any = [
    {
        img: Photo,
        name: "Mukul Pathak",
        destination: "Teacher",
    },
    {
        img: Photo,
        name: "Ret Dr. Praveen Raddy",
        destination: "Doctor, Soldier",
    },
    {
        img: Photo,
        name: "Lady Pilot Vistara",
        destination: "Career",
    },

    {
        img: Photo,
        name: "Nikhil Nanda",
        destination: "Entrepreneur",
    },
    {
        img: Photo,
        name: "Nikhil Chopra",
        destination: "Sports",
    },
    {
        img: Photo,
        name: "Rajendra Drola",
        destination: "Gym",
    },
    {
        img: Photo,
        name: "Pradeep Ji Bhaiya Ji",
        destination: "Religious Leader",
    },
    {
        img: Photo,
        name: "Siddharth Upadhye",
        destination: "Career, Youth",
    },
    {
        img: Photo,
        name: "Seva Bharti",
        destination: "NGO",
    },
    {
        img: Photo,
        name: "Jyoti Shukla ",
        destination: "Teacher",
    },
    {
        img: Photo,
        name: "Bollywood star",
        destination: "Social Media",
    },
    {
        img: Photo,
        name: "Jatin Sarthak Ji",
        destination: "RSS",
    },
    {
        img: Photo,
        name: "Dr. Capt. Richa Sood",
        destination: "Mahaila Samiti",
    },
    {
        img: Photo,
        name: "Ruchika Aggarwal",
        destination: "Religious Leader",
    },

]
let items: any = [
    {
        img: sliderpic,
        title: "Hanuman Ji In Jhakoo, Shimla",
        description: "The Jakhu Hanuman Statue in Shimla is a towering 108-foot-tall marvel, one of the world's tallest Hanuman statues. Created mainly from concrete, with intricate metal and stone details, it's a remarkable work of art. Sculptor Naresh Kumar from Pilani, Rajasthan, tackled this massive project, overcoming challenges like Shimla's harsh winters. To ensure stability, the statue is anchored with about 178 feet of underground support. This iconic landmark symbolizes faith, devotion, & unwavering dedication to Lord Hanuman's legacy."
    },
    {
        img: sliderpic2,
        title: "Hanuman Ji In Gujarat",
        description: "Nikhil Nanda, a visionary leader, unveils plans for a majestic 108-foot-tall Lord Hanuman idol in our city, courtesy of the Shree Harish Chander Nanda Education and Charitable Trust. With Nikhil Nanda presiding over the foundation stone-laying ceremony, this marks a significant step toward spiritual magnificence. Following the footsteps of similar idols in Himachal Pradesh and Gujarat, construction begins in March, and within two years, this resplendent testament to faith will grace our city at 108 feet in height."
    },
    {
        img: sliderpic3,
        title: "Hanuman Ji In Rameshwaram",
        description: "Nikhil Nanda, a distinguished entrepreneur and ardent devotee of Hanuman, spearheads the creation and installation of this monumental statue. Through the HC Nanda Foundation, founded by him, Nikhil Nanda is on an incredible mission to erect the largest Hanuman statues in all corners of India. Already gracing Shimla in the north and Gujarat in the east, this divine endeavor now extends its reach to the southern shores of Rameshwaram. As the work on this awe-inspiring Hanuman statue nears completion, it is poised to become the iconic symbol of Rameshwaram, a testament to faith and devotion."
    },
]

export default function Home() {
    const [selectedOption, setSelectedOption] = useState(10);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
      });
    const handleSelectChange = (event:any) => {
        setSelectedOption(event.target.value);
      };
      const handleInputChange = (event:any) => {
        const { id, value } = event.target;
        setFormData({
          ...formData,
          [id]: value,
        });
      };
      const handleSubmit = (event:any) => {
        event.preventDefault();
        // You can handle the form submission here, e.g., send data to a server
        console.log('Form submitted with data:', formData, 'Selected Option:', selectedOption);
      };
    return (
        <Box>
            <Grid container>
                <Grid xs={12} id="Home">
                    <Box className="bannerHeadBoxFirst">
                        <Box className="blackBgSection1"></Box>
                    </Box>
                </Grid>
            </Grid>
            <Box className="boxPaddingGrid">
                <Grid container>
                    <Grid xs={12} id="OurCampaign">
                        <Box style={{ position: "relative" }} className="">
                            <Typography variant="h3" sx={{ fontFamily: "'Bebas Neue', sans-serif", margin:"20px 0" }}>OUR CAMPAIGN</Typography>
                            <img src={OurComp} className="width100" />
                        </Box>
                        <Grid container>
                            <Grid xs={12} sm={6}>
                                <Box sx={{ margin: "20px 0" }}>
                                    <Typography variant="body1" sx={{ fontSize: "20px", color: "#26201A", marginRight:{xs:"0", sm:"0", md:"0", lg:"20px"} }}>
                                        <strong>Welcome</strong> to the Sashakt Yuva Abhiyaan, sparked by Nikhil Nanda's visionary zeal for a self-reliant India. Our platform is the gateway to a life of empowerment and excitement, offering dynamic skill-building, enriching education, and enticing opportunities.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <Box sx={{ margin: "20px 0" }}>
                                    <Typography variant="body1" sx={{ fontSize: "20px", color: "#7A726C", marginLeft:{xs:"0", sm:"0", md:"0", lg:"20px"} }}>
                                        We're here to help you pave your way to personal independence and success. Join our vibrant community, and together, we'll sculpt an exhilarating future where your dreams become your reality, and our nation's potential knows no bounds.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
                    <Grid container>
                        <Grid xs={12} sx={{ position: "relative" }}>
                            <Box className="boxLeftRightImg" sx={{ textAlign: "center" }}>
                                <Typography variant="h4" sx={{ fontFamily: "'Bebas Neue', sans-serif", fontSize: { xs: "24px", sm: "24px", md: "48px" } }}>
                                    OUR MISSION
                                </Typography>
                                <Typography variant="body2" sx={{ padding: { xs: "10px", sm: "10px", md: "10px 205px" }, color: "#7A726C", fontSize: { xs: "16px", sm: "16px", md: "24px" } }}>
                                    “Our mission is to empower the youth, providing them with opportunities and knowledge to thrive in social and personal life.”
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
                    <Grid container>
                        <Grid xs={12} sx={{ position: "relative" }}>
                            <Box className="boxLeftRightImg" sx={{ textAlign: "center" }}>
                                <Typography variant="h4" sx={{ fontFamily: "'Bebas Neue', sans-serif", fontSize: { xs: "24px", sm: "24px", md: "48px" } }}>
                                    VISION
                                </Typography>
                                <Typography variant="body2" sx={{ padding: { xs: "10px", sm: "10px", md: "10px 205px" }, color: "#7A726C", fontSize: { xs: "16px", sm: "16px", md: "24px" } }}>
                                    “We envision a future where young citizens are informed, inspired, and actively engaged in shaping their communities and society.”
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box id="Services" sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
                    <Grid container>
                        <Grid xs={12} sx={{ position: "relative" }}>
                            <Box className="ServiceBox" sx={{ margin: "20px 0" }}>
                                <Typography variant="h3" sx={{ fontFamily: "'Bebas Neue', sans-serif" }}>Services</Typography>

                            </Box>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={4} sx={{ position: "relative" }}>
                                    <Box className="servicesBoxLayout">
                                        <Box>
                                            <Box className="">
                                                <Box sx={{ marginTop: "15px" }}>
                                                    <img className="imgRed" src={lapRed} />
                                                    <img className="imgBlack" src={lapBlack} />
                                                </Box>
                                                <Typography variant="body1" className="borderBottomServices"></Typography>
                                                <Typography variant="h4" sx={{ margin: "20px 0", fontSize: "20px", fontWeight: "600", fontFamily:"'Poppins', sans-serif" }}>
                                                    Platform To Empower Young Citizens
                                                </Typography>
                                                <Typography variant="body1" sx={{ margin: "20px 0", height:{xs:"auto", sm:"auto", md:"auto", lg:"126px"} }}>
                                                    Through our mobile application, engaging video blogs, and thought-provoking articles, we inspire and educate the youth on public engagement.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={4} sx={{ position: "relative" }}>
                                    <Box className="servicesBoxLayout">
                                        <Box>
                                            <Box className="">
                                                <Box sx={{ marginTop: "15px" }}>
                                                    <img className="imgRed" src={multUserRed} />
                                                    <img className="imgBlack" src={multiUserBlack} />
                                                </Box>
                                                <Typography variant="body1" className="borderBottomServices"></Typography>
                                                <Typography variant="h4" sx={{ margin: "20px 0", fontSize: "20px", fontWeight: "600",fontFamily:"'Poppins', sans-serif" }}>
                                                    Meaningful Connections With Community
                                                </Typography>
                                                <Typography variant="body1" sx={{ margin: "20px 0",height:{xs:"auto", sm:"auto", md:"auto", lg:"126px"} }}>
                                                    Discover the benefits and schemes launched by your government. Join us in our online events, where you'll have the chance to connect with influential role models.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={4} sx={{ position: "relative" }}>
                                    <Box className="servicesBoxLayout">
                                        <Box>
                                            <Box className="">
                                                <Box sx={{ marginTop: "15px" }}>
                                                    <img className="imgRed" src={userRed} />
                                                    <img className="imgBlack" src={userBlack} />
                                                </Box>
                                                <Typography variant="body1" className="borderBottomServices"></Typography>
                                                <Typography variant="h4" sx={{ margin: "20px 0", fontSize: "20px", fontWeight: "600",fontFamily:"'Poppins', sans-serif" }}>
                                                    On-field Events With Popular Figures
                                                </Typography>
                                                <Typography variant="body1" sx={{ margin: "20px 0",height:{xs:"auto", sm:"auto", md:"auto", lg:"126px"} }}>
                                                    On-field events with popular figures who can inspire and empower you further. Explore our multifaceted approach to embracing your life and contributing to society.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ borderBottom: "1px solid #b0abab", margin: { xs: "20px 0", sm: "20px 0", md: "50px 0" } }}>
                </Box>

                <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
                    <Grid container>
                        <Grid xs={12} sm={12} md={5} sx={{ position: "relative" }}>
                            <Box className="ServiceBox" sx={{ margin: "20px 0" }}>
                                <Typography variant="h6" sx={{ margin: "20px 0" }}><span style={{ background: "#FFE377", padding: "10px 20px", color: "#000" }}>Application Features</span></Typography>
                                <Typography variant="h3" sx={{ fontFamily: "'Bebas Neue', sans-serif", margin: "40px 0" }}>FLASH, Videos & Articles</Typography>
                                <Box className="listBox">
                                    <ul>
                                        <li><span><img src={shape} /></span><p>Elevate your inspiration with bite-sized, powerful videos.</p></li>
                                        <li><span><img src={shape} /></span><p>Explore our Flash feature for quick doses of motivation, education, and aspiration.</p></li>
                                        <li><span><img src={shape} /></span><p>Latest Blogs and News via Videos and Articles.</p></li>
                                        <li><span><img src={shape} /></span><p>Stay informed with our engaging video blogs and articles.</p></li>
                                        <li><span><img src={shape} /></span><p>Curated with the help of influential role models.</p></li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={7} sx={{ position: "relative" }}>
                            <Box sx={{ margin: { xs: "20px 0", sm: "30px 0", md: "70px 0" } }}>
                                <img src={flesh} className="width100" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ borderBottom: "1px solid #b0abab", margin: { xs: "20px 0", sm: "20px 0", md: "50px 0" } }}>
                </Box>

                <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
                    <Grid container>
                        <Grid xs={12} sm={12} md={6} sx={{ position: "relative" }}>
                            <Box sx={{ margin: { xs: "20px 0", sm: "20px 0", md: "20px 0" } }}>
                                <Box sx={{marginRight:{xs:"0", sm:"0", md:"0", lg:"30px"}}}>
                                <img src={online} className="width100" />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} sx={{ position: "relative" }}>
                            <Box className="ServiceBox" sx={{ margin: { xs: "20px 0", sm: "20px 0", md: "120px 0" } }}>
                                <Box sx={{marginLeft:{xs:"0", sm:"0", md:"0", lg:"30px"}}}>
                                <Typography variant="h3" sx={{ fontFamily: "'Bebas Neue', sans-serif", margin: "40px 0" }}>ONLINE & Field EVENTS</Typography>
                                <Box className="listBox">
                                    <ul>
                                        <li><span><img src={shape} /></span><p>Participate in video conferencing sessions.</p></li>
                                        <li><span><img src={shape} /></span><p>Discuss topics with inspirational role models & subject matter experts.</p></li>
                                        <li><span><img src={shape} /></span><p>Join us for periodic on-field events with renowned personalities who inspire and empower citizens.</p></li>
                                    </ul>
                                </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ borderBottom: "1px solid #b0abab", margin: { xs: "20px 0", sm: "20px 0", md: "50px 0" } }}>
                </Box>
                <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
                    <Grid container>
                        <Grid xs={12} sm={12} md={6} sx={{ position: "relative" }}>
                            <Box className="ServiceBox" sx={{ margin: { xs: "20px 0", sm: "20px 0", md: "120px 0" } }}>
                            <Box sx={{marginRight:{xs:"0", sm:"0", md:"0", lg:"30px"}}}>
                                <Typography variant="h3" sx={{ fontFamily: "'Bebas Neue', sans-serif", margin: "40px 0" }}>NETWORK & Benefits</Typography>
                                <Box className="listBox">
                                    <ul>
                                        <li><span><img src={shape} /></span><p>Connect with friends and family, and expand your network within our community.</p></li>
                                        <li><span><img src={shape} /></span><p>Discover actives schemes and benefits introduced by your government.</p></li>
                                        <li><span><img src={shape} /></span><p>Enrol yourself and your network to the benefits directly through the application.</p></li>
                                    </ul>
                                </Box>
                            </Box>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} sx={{ position: "relative" }}>
                            <Box sx={{ margin: { xs: "20px 0", sm: "20px 0", md: "20px 0" } }}>
                            <Box sx={{marginLeft:{xs:"0", sm:"0", md:"0", lg:"30px"}}}>
                                <img src={network} className="width100" />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>



            </Box>

            <Box sx={{ background: "#FFEEE3" }} id="Impact">
                <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
                    <Box className="boxPaddingGrid">
                        <Grid container>
                            <Grid xs={12} sx={{ position: "relative" }}>
                                <Box className="ServiceBox" sx={{ margin: "20px 0" }}>
                                    <Typography variant="h3" sx={{ fontFamily: "'Bebas Neue', sans-serif" }}>IMPACT</Typography>

                                </Box>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ position: "relative" }}>
                                        <Box sx={{ width: "100%", top: { xs: "0", sm: "30%", md: "15%", lg: "25%" } }}>
                                            <Carousel className="sliderCarousel"
                                                NextIcon={<ChevronRightIcon />}
                                                PrevIcon={<ChevronLeftIcon />}
                                                indicators={true}
                                                indicatorIconButtonProps={{

                                                    style: {
                                                        padding: '5px',    // 1
                                                        color: '#FF6500',
                                                        position: "relative",
                                                        zIndex: "999"
                                                        // 3
                                                    }
                                                }}
                                                activeIndicatorIconButtonProps={{
                                                    style: {
                                                        color: "rgb(201 191 191)",
                                                        border: '1px solid #fff' // 2
                                                    }
                                                }}
                                                autoPlay={false}

                                                indicatorContainerProps={{
                                                    //className: "buttinIndicator",
                                                    style: {
                                                        display: "flex",
                                                        marginBottom: "0",
                                                        zIndex: "999",
                                                        justifyContent: "center",
                                                        position: "absolute",
                                                        bottom: "0"

                                                    }

                                                }}

                                            >
                                                {
                                                    items.map((item: any, i: any) => <ItemSlider key={i} item={item} />)
                                                }
                                            </Carousel>

                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ background: "#fff" }} id="AdvisoryBoard">
                <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
                    <Box className="boxPaddingGrid">
                        <Grid container>
                            <Grid xs={12} sx={{ position: "relative" }}>
                                <Box className="ServiceBox" sx={{ margin: "20px 0" }}>
                                    <Typography variant="h3" sx={{ fontFamily: "'Bebas Neue', sans-serif" }}>ADVISORY BOARD</Typography>

                                </Box>
                                <Box sx={{ marginTop: { xs: "10px", sm: "10px", md: "10px", lg: "50px" } }}>
                                    <Grid container>
                                        {
                                            OurTeams.map((item: any, i: any) => <OurTeam key={i} item={item} />)
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ background: "#FFEEE3" }} id="GetInvolved">
                <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
                    <Box className="boxPaddingGrid">
                        <Grid container>
                            <Grid xs={12} sx={{ position: "relative" }}>
                                <Box className="ServiceBox" sx={{ margin: "20px 0" }}>
                                    <Typography variant="h3" sx={{ fontFamily: "'Bebas Neue', sans-serif" }}>GET INVOLVED</Typography>

                                </Box>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={5}>
                                        <Box sx={{ margin: "30px 0" }}>
                                            <Typography sx={{ fontSize: { xs: "16px", sm: "16px", md: "20px", lg: "20px" }, lineHeight: "30px", fontWeight: "600" }} variant="h3">Volunteer Opportunities</Typography>
                                            <Typography sx={{ fontSize: { xs: "14px", sm: "14px", md: "16px", lg: "16px" }, lineHeight: "28px", color: "#7A726C" }} variant="body1">Find opportunities to volunteer and make a difference in your community.</Typography>
                                        </Box>
                                        <Box sx={{ margin: "30px 0" }}>
                                            <Typography sx={{ fontSize: { xs: "16px", sm: "16px", md: "20px", lg: "20px" }, lineHeight: "30px", fontWeight: "600" }} variant="h3">Partner With Us</Typography>
                                            <Typography sx={{ fontSize: { xs: "14px", sm: "14px", md: "16px", lg: "16px" }, lineHeight: "28px", color: "#7A726C" }} variant="body1">Organizations are welcome to collaborate with us in our mission to empower the youth.</Typography>
                                        </Box>
                                        <Box sx={{ margin: "30px 0" }}>
                                            <Typography sx={{ fontSize: { xs: "16px", sm: "16px", md: "20px", lg: "20px" }, lineHeight: "30px", fontWeight: "600" }} variant="h3">Join Our Community</Typography>
                                            <Typography sx={{ fontSize: { xs: "14px", sm: "14px", md: "16px", lg: "16px" }, lineHeight: "28px", color: "#7A726C" }} variant="body1">Become a part of our growing community, engage with like-minded individuals, and shape a better future together. </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={1}></Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <Box className="formBox">
                                            <form onSubmit={handleSubmit}>
                                                <Grid container>
                                                    <Grid xs={12} sm={12}>
                                                        <TextField id="outlined-basic"
                                                            placeholder="Enter Your Name" 
                                                            required
                                                            variant="outlined"
                                                            //@ts-ignore
                                                            id="name"
                                                            value={formData.name}
            onChange={handleInputChange}
                                                            sx={{
                                                                margin: "15px 0",
                                                                width: "100%",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderRadius: '5px',
                                                                        borderColor: 'transparent',
                                                                    },
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    color: '#84989F',
                                                                },
                                                            }}
                                                            InputProps={{
                                                                sx: {
                                                                    color: '#84989F',
                                                                    borderRadius: '5px',
                                                                    backgroundColor: '#fff',
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12}>
                                                        <TextField id="outlined-basic"
                                                            placeholder="Enter Your Email" variant="outlined"
                                                            type="email"
                                                            required
                                                            //@ts-ignore
                                                            id="email"
                                                            value={formData.email}
            onChange={handleInputChange}
                                                            sx={{
                                                                margin: "15px 0",
                                                                width: "100%",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderRadius: '5px',
                                                                        borderColor: 'transparent',
                                                                    },
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    color: '#84989F',
                                                                },
                                                            }}
                                                            InputProps={{
                                                                sx: {
                                                                    color: '#84989F',
                                                                    borderRadius: '5px',
                                                                    backgroundColor: '#fff',
                                                                },
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid xs={12} sm={12}>

                                                      <Box sx={{margin:"15px 0"}}>
                                                      <Select
                                                            fullWidth

                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            placeholder="Select Option"
                                                            required
                                                            //@ts-ignore
                                                            inputProps={{
                                                                sx: {
                                                                    color: '#84989F',
                                                                    borderRadius: '5px',
                                                                    backgroundColor: '#fff',
                                                                },
                                                            }}
                                                            value={selectedOption}
      onChange={handleSelectChange}

                                                        >
                                                            <MenuItem disabled value={10}>Select Option</MenuItem>
                                                            <MenuItem value={20}>Volunteer</MenuItem>
                                                            <MenuItem value={30}>Partner</MenuItem>
                                                        </Select>
                                                      </Box>

                                                    </Grid>

                                                    <Button type="submit" sx={{color:"#fff", background:"#E37D00", padding:"10px 20px", margin:"20px 0", borderRadius:"30px",
                                                     '&:hover': {
                                                        backgroundColor: '#E37D00', 
                                                      },
                                                 }}>Get Involved</Button>
                                                </Grid>
                                            </form>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ background: "#fff" }} id="ContactUs">
                <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" }, textAlign:"center" }}>
                    <Box className="boxPaddingGrid">
                        <Grid container>
                            <Grid xs={12} sx={{ position: "relative" }}>
                                <Box className="ServiceBox" sx={{ margin: "20px 0" }}>
                                    <Typography variant="h3" sx={{ fontFamily: "'Bebas Neue', sans-serif" }}>CONTACT US</Typography>

                                </Box>
                                <Box sx={{ marginTop: { xs: "10px", sm: "10px", md: "10px", lg: "10px" } }}>
                                    <Typography sx={{color:"#7A726C", fontSize:"16px", margin:"20px 0"}} variant="body1">For inquiries, collaborations, or to share your thoughts, please reach out to us.</Typography>

                                <Box sx={{display:"flex", justifyContent:"center", flexDirection:{xs:"column", sm:"column", md:"inherit"}}}>
                                <Box sx={{padding:"10px"}}>
                                    <Box sx={{display:"flex", justifyContent:"center"}}>
                                    <img src={userIcon} width="20" height="20" style={{marginTop:"4px", marginRight:"5px"}} />
                                    <Typography sx={{color:"#26201A", fontSize:"20px", fontWeight:"600"}} variant="body1">KARTIKEYA CHAUDHARY</Typography>
                                    </Box>
                                    <p style={{color:"#7A726C"}}>
                                    <a style={{color:"#7A726C", textDecoration:"none"}} href={`mailto:kartikeya@gmail.com`}>kartikeya.chaudhary@vizexec.com</a>
                                    </p>
                                </Box>
                                <Box sx={{padding:"10px"}}>
                                    <Box sx={{display:"flex", justifyContent:"center"}}>
                                    <img src={userIcon} width="20" height="20" style={{marginTop:"4px",marginRight:"5px"}} />
                                    <Typography sx={{color:"#26201A", fontSize:"20px", fontWeight:"600"}} variant="body1">LAKSHYA JAIN</Typography>
                                    </Box>
                                    <p style={{color:"#7A726C"}}>
                                    <a style={{color:"#7A726C", textDecoration:"none"}} href={`mailto:lakshay@gmail.com`}>lakshya.jain@vizexec.com</a>
                                        </p>
                                </Box>
                                </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
