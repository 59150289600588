import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

const ItemSlider = (props: any) => {
  return (
    <>

      <Grid container>
        <Grid xs={12} sm={12} md={1}></Grid>
        <Grid xs={12} sm={12} md={10}>
            <Box sx={{background:"#fff", padding:"40px 22px", borderRadius:"20px"}}>
                <Grid container>
                <Grid xs={12} sm={4} md={4}>
                        <Box sx={{ display: { xs: "flex", sm: "flex", md: "grid" }, justifyContent: "space-around" }}>
                          <img src={props.item.img} className="width100" style={{borderRadius:"5px"}} />
                        </Box>

                      </Grid>
     <Grid xs={12} sm={8} md={8}>
                        <Box className="textDirector" sx={{ padding: { xs: "10px", sm: "10px", md: "0 50px" } }}>
                        <Typography sx={{ fontSize: "20px", fontWeight: "600", marginBottom:{xs:"0",sm:"0",md:"20px"}, lineHeight:"30px" }} className="colorWhite" variant="h5">{props.item.title}</Typography>
                          <Typography className="colorWhite" variant="h3" sx={{ fontSize: { xs: "12px", sm: "14px", md: "13px", lg: "16px" }, marginBottom: {xs:"5px",sm:"5px", md:"15px",lg:"25px"}, lineHeight:{xs:"auto", sm:"auto", md:"auto", lg:"28px"} }}>{props.item.description}</Typography>
                        </Box>
                      </Grid>
                </Grid>
            </Box>
        </Grid>
        <Grid xs={12} sm={8} md={1}></Grid>
      </Grid>
    </>
  )
}

export default ItemSlider
